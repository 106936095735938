<template>
    <div class="content d-flex flex-column">
        <Accueil ref="home" />
        <Services ref="services" />

        <!-- <div style="height: 1000px"></div> -->
    </div>
</template>

<script>
import Accueil from "@/components/_Accueil.vue";
import Services from "@/components/_Services.vue";

export default {
    components: {
        Accueil,
        Services,
    },
};
</script>
