<template>
    <div id="app" :class="{ shrink: isShrink }">
        <template>
            <b-navbar toggleable="md" id="navbar">
                <b-navbar-toggle target="navbar-toggle-collapse" id="nav_toggle"></b-navbar-toggle>

                <b-navbar-brand>
                    <img src="./assets/img/logo-250.jpg" alt="" />
                </b-navbar-brand>

                <b-collapse id="navbar-toggle-collapse" is-nav>
                    <b-navbar-nav class="ml-auto align-items-center">
                        <b-nav-item @click="scrollToElement('home')" to="/#">Accueil</b-nav-item>
                        <b-nav-item class="text-400" @click="scrollToElement('services')" to="/#">Services</b-nav-item>
                        <!-- <b-button class="my-2 rounded-0" @click="refreshIsShrink()">Contact</b-button> -->
                    </b-navbar-nav>
                </b-collapse>
            </b-navbar>
            <router-view ref="view"></router-view>

            <footer>
                <div class="text-center text-primary fs-sm my-4">Créé par <a href="https://mod1.fr/">MOD1 CONSULTING</a> ©2022</div>
            </footer>
        </template>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isShrink: null,
        };
    },
    methods: {
        scrollToElement(target) {
            let element = this.$refs.view.$refs[target];
            const y = element.$el.getBoundingClientRect().top + window.pageYOffset - 60;
            window.scrollTo({ top: y, behavior: "smooth" });
        },
    },
    mounted() {
        const refreshIsShrink = () => {
            this.$data.isShrink = document.documentElement.scrollTop >= 80;
        };

        refreshIsShrink();

        this.$nextTick(function () {
            window.addEventListener("scroll", refreshIsShrink);
        });
    },
};
</script>
